<template>
  <div>
    <div
      v-if="groupedByConvoy && Object.keys(groupedByConvoy).length > 0"
      class="card"
    >
      <div
        v-for="(convoyGroup, convoyNumber) in groupedByConvoy"
        :key="convoyNumber"
        class="col-md-12 mb-4"
      >
        <div class="card">
          <!-- Encabezado con el número de convoy -->
          <div class="card-header text-center">
            <h2 class="card-title fw-bold">
              Convoy Número: {{ convoyNumber }}
            </h2>
          </div>

          <table
            class="table table-hover text-center table-bordered table-striped"
          >
            <thead
              class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center"
            >
              <tr>
                <th>Barcaza</th>
                <th>Guia</th>
                <th>Fecha Cargue</th>
                <th>Producto</th>
                <th>Volumen Frontera GSV</th>
                <th>Volumen Trafigura GSV</th>
                <th>Volumen Total NSV</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in convoyGroup" :key="index">
                <td>{{ item.barcaza.nombre }}</td>
                <td>
                  {{ item.guia ? item.guia.numero : "N/A" }} -
                  {{ item.guia ? item.guia.digito_verificacion : "N/A" }}
                </td>
                <td>{{ item.solicitud_fluvial.fecha }}</td>
                <td>{{ item.producto.nombre }}</td>
                <td>{{ item.gsv_frontera }}</td>
                <td>{{ item.gsv_trafigura }}</td>
                <td>
                  {{
                    parseFloat(item.gsv_frontera) +
                      parseFloat(item.gsv_trafigura)
                  }}
                </td>
                <td>
                  <button @click="openModal(item)" class="btn btn-info">
                    <i class="fas fa-eye"></i>
                  </button>
                  <button @click="openModalGuia(item)" class="btn btn-warning">
                    <i class="fas fa-clipboard-list"></i>
                  </button>
                  <button @click="destroy(item)" type="button" class="btn btn-sm btn-danger">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <SolicitudGuiasAsignadas ref="SolicitudGuiasAsignadas" />
    <DetGuiasFluvialForm ref="DetGuiasFluvialForm" />

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SolicitudGuiasAsignadas from "./SolicitudGuiasAsignadas";
import DetGuiasFluvialForm from "./DetGuiasFluvialForm";


export default {
  name: "SolicitudFormDet",
  components: {
    SolicitudGuiasAsignadas,
    DetGuiasFluvialForm,
  },
  data() {
    return {
      guidesData: null,

      data: [],
      groupedByConvoy: {},
      file: null,
      asignacion: {},
      nsvDeseado: 0,
      volumenAlcanzado: 0,
      volumenFaltante: 0,
      asignaciones: [],
      guiasSeleccionadas: [],
      cargando: false,
      sitio: null,
      guias: [],
      showDetails: {},
      selectedFechas: {},
      selectedGuias: {},
      totalNSV: 0,
      totalGSV: 0,
      id: "",
      metodo: "",
      listasForms: {
        estados: [],
        sitios: [],
        productos: [],
        tipoProductos: [],
      },
      form: {
        sitio_id: null,
        fecha: null,
        estado: null,
      },
      accion: "",
      solicitudEmpresas: {},
      cantidades_empresas: {
        totalLarga: 0,
        totalCorta: 0,
      },
      val_fecha: false,
    };
  },

  methods: {
    async index() {
      axios
        .get(
          "/api/hidrocarburos/solicitudesFluviales/DetSolicitud/" +
            this.$parent.id
        )
        .then((response) => {
          this.data = response.data;
          this.groupedByConvoy = this.groupByConvoy(this.data); // Agrupar por convoy
        });
    },

    // Función para agrupar los datos por convoy
    groupByConvoy(data) {
      return data.reduce((groups, item) => {
        if (!groups[item.convoy]) {
          groups[item.convoy] = [];
        }
        groups[item.convoy].push(item);
        return groups;
      }, {});
    },

    openModal(item) {
      // Llama al método openModal del componente hijo y pasa las guías seleccionadas
      this.$refs.SolicitudGuiasAsignadas.openModal(item);
    },
    openModalGuia(item) {
      // Llama al método openModal del componente hijo y pasa las guías seleccionadas
      this.$refs.DetGuiasFluvialForm.openModalGuia(item);
    },
    destroy(item) { 
  this.$swal({
    title: "¿Está seguro de eliminar esta Solicitud?",
    text: "Los cambios no se pueden revertir!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Sí, Eliminar!",
  }).then((result) => {
    if (result.value) {
      axios
        .delete("/api/hidrocarburos/solicitudesFluviales/eliminar/" + item.id)
        .then(() => {
          this.index(); // Llamar correctamente a la función para actualizar la tabla
          this.$swal({
            icon: "success",
            title: "Se eliminó la Solicitud exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    }
  });
}

  },

  beforeMount() {
    this.index();
  },
};
</script>
