<template>
  <div
    v-if="showModal"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Guías Asignadas</h5>
          <button
            type="button"
            class="close"
            @click="closeModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-4 col-6 d-flex align-items-stretch flex-column">
              <div class="small-box bg-sobrante">
                <div class="inner textWhite">
                  <h3>
                     {{ item.gsv_frontera }}
                   </h3>
                  <p>Volumen Frontera</p>
                </div>
                <div class="icon">
                  <i class="fas fa-oil-can textWhite"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-6 d-flex align-items-stretch flex-column">
              <div class="small-box bg-cumple">
                <div class="inner textWhite">
                  <h3>
                    {{ totalGSV }}
                   </h3>
                  <p>Volumen GSV</p>
                </div>
                <div class="icon">
                  <i class="fas fa-oil-can  textWhite"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-6 d-flex align-items-stretch flex-column">
              <div class="small-box bg-cumple">
                <div class="inner textWhite">
                  <h3>
                     {{ totalNSV }}
                   </h3>
                  <p>Volumen NSV</p>
                </div>
                <div class="icon">
                  <i class="fas fa-oil-can  textWhite"></i>
                </div>
              </div>
            </div>
          </div>

          <table class="table table-hover text-center table-bordered table-striped ">
            <thead  class="bg-dark bg-frontera-top-left pt-2 pb-2 text-white text-center">
              <tr>
                <th>Guía</th>
                <th>Fecha Descargue</th>
                <th>Tipo Producto</th>
                <th>Producto liquido</th>
                <th>Barriles GSV</th>
                <th>Barriles NSV</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in item.cierre_guia"
                :key="index"
              >
                <td>
                  {{ item.guia.numero }}-{{
                    item.guia.digito_verificacion
                  }}
                </td>
                <td>
                  {{ item.fecha_hora_descargue }}
                </td>
                <td>{{ item.guia.det_guias[0].tipo_producto.nombre }}</td>
                <td>{{ item.guia.det_guias[0].producto_liquido.nombre }}</td>
                <td>{{ item.barriles_gsv }}</td>
                <td>{{ item.barriles_nsv }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolicitudGuiasAsignadas",
  data() {
    return {
      showModal: false,
      item: [],
      totalGSV: 0,
      totalNSV: 0,
    };
  },
  methods: {
    openModal(item) {
      this.item = item;
      this.totalGSV = item.cierre_guia
    ? item.cierre_guia.reduce((sum, guia) => sum + parseFloat(guia.barriles_gsv || 0), 0)
    : 0;
    this.totalNSV = item.cierre_guia
    ? item.cierre_guia.reduce((sum, guia) => sum + parseFloat(guia.barriles_nsv || 0), 0)
    : 0;

      this.showModal = true; 
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-sobrante {
  background-color: rgb(131, 197, 222);
}
.bg-cumple {
  background-color: rgb(171, 219, 171);
}
.modal-dialog {
  max-width: 80%;
}

.modal-content {
  max-height: 90vh; /* Evita que el modal sea más alto que la pantalla */
  overflow: hidden; /* Para evitar que todo el modal tenga scroll */
}

.modal-body {
  max-height: 60vh; /* Ajusta el valor según lo necesario */
  overflow-y: auto; /* Agrega desplazamiento vertical cuando el contenido sea muy grande */
}
</style>
